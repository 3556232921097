import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Container } from 'react-bootstrap';
import PropertyList from './PropertyList';
import { Logo } from '@components/icon/icon';
import "./NoResultProperties.scss";

const GET_PROPERTIES = gql`
  query GetProperties($searchType: String!) {
    properties(
      where: { publish: true, search_type: $searchType },
      sort: "createdAt:desc",
      limit: 4
    ) {
      id
      title
      price
      images
      display_address
      description
      negotiator
      slug
      bedroom
      bathroom
      reception
      ggfx_results {
        id
        transforms
        src_cftle
      }
    }
  }
`;

const NoResultProperties = ({ searchType }) => {

  const { loading, error, data } = useQuery(GET_PROPERTIES, {
    variables: { searchType },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <div className="no-result-properties static-gql-loading">
        <Container>
          <Logo />
          <div className="gql-loading">Loading ...</div>
        </Container>
      </div>
    );
  }

  if (error) {
    return null;
  }

  const properties = data?.properties || [];
  
  return (
    <div className="col-lg-12 no-result-properties">
      <div className="section-wrap spacing-bottom-40 text-center">
        <h6 className="spacing-bottom-24">Unfortunately, we do not currently have any properties that match your search criteria.</h6>
        <p className="description mb-0">
          We have selected some of our showcase properties for you to browse below. Alternatively, you can search again in the search bar above.
        </p>
      </div>
      {properties.length > 0 && 
        <PropertyList properties={properties} searchType={searchType} />}
    </div>
  );
};

export default NoResultProperties;